import { SHOWTOASTER, HIDETOASTER } from "../actions";


const initial = {
    show : false,
    msg : '',
    flag : ''
}

export const toasterReducer = (state = initial, action)=>{
    switch (action.type) {
        case SHOWTOASTER:
            
                return{
                    ...state,
                    show : action.payload.show,
                    msg : action.payload.msg,
                    flag : action.payload.flag
                }
        
            
            break;
            case HIDETOASTER:
            
                return{
                    ...state,
                    show : action.payload.show,
                    msg : action.payload.msg,
                    flag : action.payload.flag
                }
        
            
            break;
    
        default: return state;
            break;
    }
}